import React from "react";

const About = () => {
    return (
        <div className="vh-100 text-light bg-dark d-flex justify-content-center align-items-center">
        <div className="w-75 row"> 

           <h1 className="my-5">About</h1> 
    <p>
        Shenzhen Daxing Software Co., Ltd. was established in May 2018. It is a company dedicated to shoe software development, functional customization, 3D personnel training, and provide comprehensive solutions for brand owners.
    </p>
    <p>
        The company has a senior technical research and development team, professional training team and dedicated after-sales team.
    </p>
    <p>
        Orang software is also exported to the global market and we have customers in Brazilian, Korean, Indonesia, Vietnamese, Turkish, Iranian, Ivorian, German, Spanish, Portuguese, Italian, Mexican and American countries.
    </p>

        

        </div>
        </div>
    )
}

export default About;