import React, { useState } from "react";
//import emailSend from "../Controllers/EmailController";

const Contact = () => {
  return (
    <div className="bg-dark vh-100 ">
      <div className="row d-flex justify-content-center bg-dark w-100">
        <div className="w-50 border rounded bg-light my-5 text-start">
          <h2 className="d-flex justify-content-center my-3">CONTACT</h2>
          <form
            id="form"
            action="https://formsubmit.co/150c086c86081c9ea88ae7d56196f880"
            method="POST"
          >
            <div className="form-group">
              <label for="name" id="nameInput" className="form-label">
                Company name:
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Company name"
                required=""
              ></input>
              <div className="invalid-feedback position-relative">
                Please, enter your company name!
              </div>
            </div>
            <div className="form-group">
              <label for="email" className="form-label">
                Company email:
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                name="email"
                placeholder="Company email"
                required=""
              ></input>
              <div className="invalid-feedback position-relative">
                Please, type a valid email
              </div>
            </div>
            <div className="form-group">
              <label for="country" id="countryInput" className="form-label">
                Country:
              </label>
              <input
                type="text"
                id="country"
                className="form-control"
                name="country"
                placeholder="What country are you from?"
                required=""
              ></input>
              <div className="invalid-feedback position-relative">
                Please, enter the country you are from!
              </div>
            </div>
            <div className="form-group" id="message">
              <label for="message" className="form-label">
                Write your message:
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="5"
                placeholder="Write your message!"
              ></textarea>
            </div>
            <div className="text-center">
              <button className="btn btn-secondary my-2 w-75" type="submit">
                Send
              </button>
            </div>
            <input type="hidden" name="_captcha" value="true"></input>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
