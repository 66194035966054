
import '../App.css';
import NavBar from '../Components/Navbar.js';
import Home from "../Components/Home.js"
import Partners from '../Components/Partners.js';
import About from '../Components/About.js';
import Contact from '../Components/Contact.js';
import Agents from '../Components/Agents.js';

function App() {
  return (
    <div className="App">      
      <NavBar />
      <Home />
      <div id="partners"></div>
      <Partners />
      <div id="about"></div>
      <About />
      <div id="contact"></div>
      <Contact />
      <Agents />     
    </div>
  );
}

export default App;
