    import React from 'react';
    import { useLocation } from "react-router-dom";
   // import { ReactComponent as Logo } from '../images/logo.svg';
const NavBar = () => {
    const location = useLocation();
    return (
        <nav className='navbar navbar-expand-lg navbar-dark fixed-top bg-dark opacity-50'>
            <div className='container'>
                <a className='navbar-brand p-0' href='/'>
                    {/* <Logo style={{width: '50px', height: '40px'}}/> */}
                </a>
                
                <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
                    <span className='navbar-toggler-icon'></span>
                </button>
                <div className='collapse navbar-collapse d-flex justify-content-center' id='navbarNav'>
                    <ul className='navbar-nav'>
                        <li className='nav-item'>
                            <a className='nav-link' href='/'>Home</a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-link' href='/Downloads'>Downloads</a>
                        </li>
                    {
                        location.pathname !== '/Downloads' && (
                            <>
                        <li className='nav-item'>
                            <a className='nav-link' href='#partners'>Partners</a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-link' href='#about'>About</a>
                        </li>
                        <li className='nav-item'>
                            <a className='nav-link' href='#contact'>Contact</a>
                        </li>
                            </>
                        )
                        
                    }
                        
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;