import React from "react";

const importAll = (r) =>    
   r.keys().map(r);

const Partners = () => {

      const images = importAll(require.context('../images/clients', false, /\.(png|jpe?g|svg|gif|webp)$/));
    return (
        <div className="bg-dark w-100 d-flex justify-content-center">
            <div className="border rounded d-flex w-75 justify-content-center row my-5">
                <h2 className="text-light my-5">Partners</h2>
                    
                <div className="d-flex justify-content-center flex-wrap">
                    {                                           
                    images.map((imagem, index) => 
                        (
                            <div className="m-3">
                                <img style={{
                                    maxWidth: "200px",
                                }}key={index} src={imagem} alt={`Imagem ${index + 1}`} />
                            </div>

                        )                  
                    )                                               
                    }
                </div>

            </div>
            
        </div>

    )
}

export default Partners;