import React from "react";
import '../Downloads.css'
import Navbar from "../Components/Navbar.js";
import codeMeter from "../images/buttons/CodeMeter_Logo_RGB.png"
import orang from "../images/buttons/orang.png"
import cmStep0 from "../images/tutorial/CodeMeter/step0.png"
import cmStep1 from "../images/tutorial/CodeMeter/step1.png"
import cmStep2 from "../images/tutorial/CodeMeter/step2.png"
import cmStep3 from "../images/tutorial/CodeMeter/step3.png"
import cmStep4 from "../images/tutorial/CodeMeter/step4.png"
import cmStep5 from "../images/tutorial/CodeMeter/step5.png"
import lcStep1 from "../images/tutorial/License/step1.png"
import lcStep2 from "../images/tutorial/License/step2.png"
import lcStep3 from "../images/tutorial/License/step3.png"
import lcStep4 from "../images/tutorial/License/step4.png"
import lcStep5 from "../images/tutorial/License/step5.png"
import lcStep6 from "../images/tutorial/License/step6.png"
import ftStep1 from "../images/tutorial/Font/step1.png"
import ftStep2 from "../images/tutorial/Font/step2.png"
import ftStep3 from "../images/tutorial/Font/step3.png" 
import ogStep1 from "../images/tutorial/Orang/step1.png" 
import ogStep2 from "../images/tutorial/Orang/step2.png" 
import ogStep3 from "../images/tutorial/Orang/step3.png" 
import ogStep4 from "../images/tutorial/Orang/step4.png" 
import ogStep5 from "../images/tutorial/Orang/step5.png" 

const Downloads = () => {

    return (
        <div className="h-100 bg-dark pt-5">
            <Navbar/>

    <div className="col row mt-5 mw-100">
        <div id="CodeMeterBtn" className="col-2 ms-4">
            <a href="https://orangrhino.sharepoint.com/:u:/s/Content/EUO4x7nmGxBCgxV_9jiRd-YBLa9Vt2PSe6oS3Epv0KIn0A?e=9dyGEZ" 
                class="btn btn-light btn-lg active " 
                role="button" 
                aria-pressed="true" 
                target="_blank" 
                rel="noopener noreferrer">
                    <div className="d-flex align-items-center w-75 text-center">
                        <img src={codeMeter} className="w-25 h-50"></img>
                        <p className="m-0">Download CodeMeter</p>
                    </div>
            </a>
        </div>
        <div className="col-2">

            <a href="https://orangrhino.sharepoint.com/:u:/s/Content/ESnOTRA2qRpHn8GKbIXH1agBx09iH3vW_8BWANiPf8e2kQ?e=xIDVc5" 
                class="btn btn-light btn-lg active" 
                role="button" 
                aria-pressed="true" 
                target="_blank" 
                rel="noopener noreferrer">
                    <div className="d-flex align-items-center w-75">
                        <img src={orang} className="w-25 h-50"></img>
                        <p className="m-0">Download Orang</p>
                    </div>
            </a>
        </div>
        <div className="col-2">
            <a href="https://orangrhino.sharepoint.com/:u:/s/Content/ETdw9N1LwotAhPmRaFOrnsMBTtQBfPDRk8qFWkpYlLe8lA?e=jLVkBG" 
                class="btn btn-light btn-lg active" 
                role="button" 
                aria-pressed="true" 
                target="_blank" 
                rel="noopener noreferrer">
                    <div className="d-flex align-items-center w-75">
                        <img src={orang} className="w-25 h-25"></img>
                        <p className="m-0">Download Grade</p>
                    </div>
            </a>
        </div>
        <div className="col-2">
            <a href="https://orangrhino.sharepoint.com/:f:/s/Content/EqQ7LTLauedLp3D9BFTvklQBEWT5KS7yF68nbYeugnQNqg?e=5niZCT" 
                class="btn btn-light btn-lg active" 
                role="button" 
                aria-pressed="true" 
                target="_blank" 
                rel="noopener noreferrer">
                    <div className="d-flex align-items-center w-75">
                        <img src={orang} className="w-25 h-25"></img>
                        <p className="m-0">Download Font</p>
                    </div>
            </a>
        </div>


    </div>

    <div className="text-light row mw-100 mt-5">
        <div className="col-3">
        <div className="border rounded ms-4 position-fixed">
            <ul class="nav flex-column">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#cmInstall">Getting Started</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#setup">Preparing the environment</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#orang">Installing Orang</a>
              </li>
            </ul>
        </div>

        </div>
            <div className="col-8" id="cmInstall">
                <p>
                    Welcome to the tutorial to install Orang, here we will show you step by step how to install and start using the most powerful Rhino tool on the market. So, let's go.
                </p> 
                <p>
                    First, you need to install CodeMeter, you can click the <a href="#CodeMeterBtn">CodeMeter</a> button above to be redirected to the installer.
                </p>
                <p>
                    If you want to install CodeMeter on a server, follow the instructions in this <a href="https://orangrhino.sharepoint.com/:b:/s/Content/EedA73bsfrlKrAhByXwvPSYBvOXRN7yx69yiaywnTvakdA?e=80J2qk">pdf</a>.
                </p>
                <p>
                    To install CodeMeter locally, run the CodeMeter Runtime and follow the steps below:
                </p>
                <div>
                    <div className="w-100 my-3 mx-5">
                        <img src={cmStep0} className="w-50"></img>
                    </div>
                    <div className="w-100 my-3 mx-5">
                        <img src={cmStep1} className="w-50"></img>
                    </div>
                    <div className="w-100 my-3 mx-5">
                        <img src={cmStep2} className="w-50"></img>
                    </div>
                    <div className="w-100 my-3 mx-5">
                        <img src={cmStep3} className="w-50"></img>
                    </div>
                    <div className="w-100 my-3 mx-5">
                        <img src={cmStep4} className="w-50"></img>
                    </div>
                    <div className="w-100 my-3 mx-5">
                        <img src={cmStep5} className="w-50"></img>
                    </div>
                </div>
                <div id="setup"></div>
                <p>
                    After completing the installation, insert the encryption key you received into a USB port and open the CodeMeter Control Center.
                </p>
                <p>
                    When you open the CodeMeter Control Center, on the left side, select the first license and click License update.
                </p>
                <div className="w-100 my-3 mx-5">
                    <img src={lcStep1} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={lcStep2} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={lcStep3} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={lcStep4} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={lcStep5} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={lcStep6} className="w-50"></img>   
                </div>
                <div id="orang"></div>
                <p>
                    After that, a license file will be generated in the specified path, send it to our support and wait, a validated license will soon be returned.
                </p>
                <p>
                    When everything is correct with your license, you must install the Orang font, and then install the software, both download links are in the buttons at the top of the page. To install, follow the steps below:
                </p>
                <p>
                    Font:
                </p>
                <div className="w-100 my-3 mx-5">
                    <img src={ftStep1} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={ftStep2} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={ftStep3} className="w-50"></img>   
                </div>
                <p>
                    Orang:
                </p>
                <div className="w-100 my-3 mx-5">
                    <img src={ogStep1} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={ogStep2} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={ogStep3} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={ogStep4} className="w-50"></img>   
                </div>
                <div className="w-100 my-3 mx-5">
                    <img src={ogStep5} className="w-50"></img>   
                </div>
                <p>
                    Now, you can run Rhino and check in the settings if Orang is installed.
                </p>
            </div>
            {/* <div className="col-4 bg-dark">
                teste
            </div> */}
            
        </div>

        </div>
    )
}

export default Downloads;