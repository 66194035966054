import React from "react";
import logo from "../images/orang.png"
//import modelo from "../images/modelo.gif";

const Home = () => {
    return (
        <div>
            <div className="background-gif"></div>
            <div className="background-overlay"></div>
            <div className="d-flex row w-100 vh-100 justify-content-center align-items-center">
            <div className="d-flex justify-content-center">
                    <div className="text-light">
                      <img className="w-25"src={logo}></img>
                            <h1>Orang Rhino</h1>
                    </div>
                </div>

                <div className="row w-75 h-50 align-items-center text-light">        
                    <p className="col-12 mb-3">
                        With Orang and Orang Grade, you can streamline your production process while also accomplishing tasks that were previously impossible.
                    </p>
                    <p className="col-12 mb-3">
                        Orang delivers a complete package of tools to the customer, including product modeling, texture application, reverse engineering, molds, morph for all types of applications, surfaces, tools for trusses, environment customization, and much more.
                    </p>
                    <p className="col-12">
                        In Orang Grade, we can scale with all types of coordination, thickness blocking, sharing of accessories such as stabilizers, heels, reinforcements, and others. Don't waste any more time and request your quote now.
                    </p>
                </div>
            </div>
        </div>
    ) 
}

export default Home;