import React from "react";

const Agents = () => {
  return (
    <div className="d-flex justify-content-center bg-dark">
      <div className="row w-100 mh-25 mb-5">
        <div className="d-flex justify-content-center text-light my-5">
          <h2>AGENTS</h2>
        </div>
        <div className="row justify-content-center flex-md-wrap p-0" styles="">
          <div
            className="card col-3 col-md-3 m-1"
            styles={{ height: "200px", width: "300px;" }}
          >
            <div className="card-body px-2">
              <h5 className="card-title">Toni Rodríguez Navarro</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                England, France, Germany, Italy, Portugal and Spain
              </h6>
              <p className="card-text">+34 639032199</p>
              <p className="card-text card-email">
                toni.navarro@orangrhino.com
              </p>
            </div>
          </div>
          <div
            className="card col-3 col-md-3 m-1"
            styles={{ height: "200px", width: "300px;" }}
          >
            <div className="card-body px-2">
              <h5 className="card-title">Nguyen Truong Son</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                Indonesia, Malaysia, South Korea, Thailand and Vietnam
              </h6>
              <p className="card-text">+84 918416100</p>
              <p className="card-text card-email">son.truong@orangrhino.com</p>
            </div>
          </div>
          <div
            className="card col-3 col-md-3 m-1"
            styles={{ height: "200px", width: "300px;" }}
          >
            <div className="card-body px-2">
              <h5 className="card-title">Eder José Uggeri</h5>
              <h6 className="card-subtitle mb-2 text-muted">Latin America</h6>
              <p className="card-text">+55 (51) 9 9419-4021</p>
              <p className="card-text card-email">eder.uggeri@orangrhino.com</p>
            </div>
          </div>
          <div
            className="card col-3 col-md-3 m-1"
            styles={{ height: "200px", width: "300px;" }}
          >
            <div className="card-body px-2">
              <h5 className="card-title">Ouyang Yunchao</h5>
              <h6 className="card-subtitle mb-2 text-muted">China</h6>
              <p className="card-text">+86 (186) 8006-5147</p>
              <p className="card-text card-email">ouyang@orangrhino.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agents;
